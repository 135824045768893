<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="mx-auto font-18">Undang Teman</h1>
      <a data-menu="menu-main" class="page-title-icon rounded-s ms-3"></a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <!-- Experience List-->
    <div class="page-content mt-4">
      <!-- Search Skill -->
      <div class="" data-card-height="250">
        <div class="text-center">
          <div class="content">
            <div @click="copyLink()" class="example">
              <div class="row mb-0">
                <div class="col">
                  <div class="search-box search-dark shadow-xl bg-theme">
                    <i class="fas fa-copy color-highlight"></i>
                    <input
                      type="text"
                      class="border-0"
                      placeholder="link"
                      v-model="inviteLink"
                      disabled="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="userList.length" class="card card-style">
          <div class="content mb-2">
            <h4>Teman yang di undang</h4>
            <p></p>
            <div class="list-group list-custom-small">
              <a v-for="user in userList" :key="user" href="#">
                <span class="fs-5">{{ user.name }}</span>
                <div class="d-flex flex-wrap justify-content-between" style="margin-top: -1rem;">
                  <div class="fw-light">
                    <small>{{ user.email }}</small>
                  </div>
                  <div class="fw-light">
                    <small>{{ this.getDate(user.registered_time) }}</small>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- End Search Skill -->
      <!-- Skill List -->
      <div v-if="userList.length === 0" class="mb-5">
        <EmptyState
          :message1="'Belum ada teman yg di undang,'"
          :message2="'undang sekarang !'"
          :cardCenter="false"
        />
      </div>
      <!-- Skill List -->
    </div>
    <!-- End Experience List-->

    <!-- Main Menu-->
    <div
      id="menu-main"
      class="menu menu-box-left rounded-0"
      data-menu-width="280"
      data-menu-active="nav-welcome"
    >
      <MenuMain @close="menuClose" />
    </div>

    <!-- Share Menu-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="370"
    ></div>

    <!-- Colors Menu-->
    <div
      id="menu-colors"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-colors.html"
      data-menu-height="480"
    >
      <MenuColors @close="menuClose" />
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
import { Toast } from "bootstrap";
import useClipboard from "vue-clipboard3";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import EmptyState from "@/components/EmptyState";

import MenuMain from "@/components/menu/MenuMain";
import MenuColors from "@/components/menu/MenuColors";
export default {
  name: "InviteFriend",
  components: {
    Header,
    Footer,
    MenuMain,
    MenuColors,
    EmptyState,
  },
  data() {
    return {
      userList: [],
      isLoading: true,
      inviteLink: "",
      errors: [],
      options: [],
    };
  },
  watch: {
    skill() {
      this.addSkill();
    },
  },
  mounted() {
    init_template();
    this.isLoading = false;
    this.getInviteLink();
    this.getUserRegister();
  },
  methods: {
    menuOpen,
    menuClose,
    getDate(date) {
      const monthName = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Ags',
        'Sep',
        'Okt',
        'Nov',
        'Des',
      ];
      if (date !== null) {
        let dateTime = new Date(date);
        let minutes = ((''+dateTime.getMinutes()).length === 1) ? '0'+dateTime.getMinutes() : dateTime.getMinutes()
        return dateTime.getDate() + " " + monthName[dateTime.getMonth()] + " " + dateTime.getFullYear() + " " + dateTime.getHours() + ":" + minutes
      } else {
        return ""
      }
    },
    async copyLink() {
      const { toClipboard } = useClipboard();

      try {
        await toClipboard(this.inviteLink);
        var notificationToast = document.getElementById("toast-2");
        notificationToast = new Toast(notificationToast);
        notificationToast.show();
        console.log("url : ", axios.defaults.baseURL + this.$route.fullPath);
      } catch (e) {
        console.error(e);
      }

      /*var fullPathUrl = axios.defaults.baseURL + this.$route.fullPath;*/

      /*navigator.clipboard.writeText(fullPathUrl);*/

      /*console.log(notificationToast);*/
      /*clipboard.writeText(fullPathUrl);*/
    },
    getUserRegister() {
      axios
        .get("/api/v1/auth/register-with-code/list")
        .then((response) => {
          this.userList = response.data;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
    getInviteLink() {
      axios
        .get("/api/v1/auth/invite-code")
        .then((response) => {
          this.inviteLink =
            axios.defaults.baseURL +
            "/sign-up/confirm/?invite_code=" +
            response.data.invite_code;
          console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>

<style>
.search-box .fa-copy {
  position: absolute;
  left: 0px;
  line-height: 52px;
  padding: 0px 15px 0px 30px;
}
</style>

